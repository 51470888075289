// @flow
import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: any): any {
    const baseUrl = 'auth';
    return api.create(`${baseUrl}`, params);
}

function userData(): any {
    const baseUrl = 'auth';
    return api.get(`${baseUrl}`);
}

function logout(): any {
    const baseUrl = 'auth';
    return api.delete(`${baseUrl}`);
}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, userData, forgotPassword, forgotPasswordConfirm };
