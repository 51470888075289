import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));


// dashboard
const AnalyticsDashboard = React.lazy(() => import('../pages/dashboard/Analytics'));

// monitoring
const MonitoringDevicesList = React.lazy(() => import('../app/monitoring/devices/DevicesList'));
const MonitoringDevice = React.lazy(() => import('../app/monitoring/devices/Device'));
const MonitoringDeviceAdd = React.lazy(() => import('../app/monitoring/devices/AddForm'));
const MonitoringObject = React.lazy(() => import('../app/monitoring/devices/Object'));


// const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce'));
// const CRMDashboard = React.lazy(() => import('../pages/dashboard/CRM'));
// const ProjectDashboard = React.lazy(() => import('../pages/dashboard/Project'));

// apps
// const CalendarApp = React.lazy(() => import('../pages/apps/Calendar/'));
// const Projects = React.lazy(() => import('../pages/apps/Projects/'));
// const ProjectDetail = React.lazy(() => import('../pages/apps/Projects/Detail/'));
// const ProjectGannt = React.lazy(() => import('../pages/apps/Projects/Gantt/'));
// const ProjectForm = React.lazy(() => import('../pages/apps/Projects/ProjectForm'));
// - chat
// const ChatApp = React.lazy(() => import('../pages/apps/Chat/'));
// - ecommece pages
// const EcommerceProducts = React.lazy(() => import('../pages/apps/Ecommerce/Products'));
// const ProductDetails = React.lazy(() => import('../pages/apps/Ecommerce/ProductDetails'));
// const Orders = React.lazy(() => import('../pages/apps/Ecommerce/Orders'));
// const OrderDetails = React.lazy(() => import('../pages/apps/Ecommerce/OrderDetails'));
// const Customers = React.lazy(() => import('../pages/apps/Ecommerce/Customers'));
// const Cart = React.lazy(() => import('../pages/apps/Ecommerce/Cart'));
// const Checkout = React.lazy(() => import('../pages/apps/Ecommerce/Checkout/'));
// const Sellers = React.lazy(() => import('../pages/apps/Ecommerce/Sellers'));
// - email
// const Inbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
// const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
// - social
// const SocialFeed = React.lazy(() => import('../pages/apps/SocialFeed/'));
// - tasks
// const TaskList = React.lazy(() => import('../pages/apps/Tasks/List/'));
// const TaskDetails = React.lazy(() => import('../pages/apps/Tasks/Details'));
// const Kanban = React.lazy(() => import('../pages/apps/Tasks/Board/'));
// - file
// const FileManager = React.lazy(() => import('../pages/apps/FileManager'));

// pages
// const Profile = React.lazy(() => import('../pages/profile'));
const Profile2 = React.lazy(() => import('../pages/profile2'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
// const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
// - other
// const Invoice = React.lazy(() => import('../pages/other/Invoice'));
// const FAQ = React.lazy(() => import('../pages/other/FAQ'));
// const Pricing = React.lazy(() => import('../pages/other/Pricing'));
// const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
// const Starter = React.lazy(() => import('../pages/other/Starter'));
// const PreLoader = React.lazy(() => import('../pages/other/PreLoader/'));
// const Timeline = React.lazy(() => import('../pages/other/Timeline'));

// const Landing = React.lazy(() => import('../pages/landing/'));

// uikit
// const Accordions = React.lazy(() => import('../pages/uikit/Accordions'));
// const Alerts = React.lazy(() => import('../pages/uikit/Alerts'));
// const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
// const Badges = React.lazy(() => import('../pages/uikit/Badges'));
// const Breadcrumbs = React.lazy(() => import('../pages/uikit/Breadcrumb'));
// const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
// const Cards = React.lazy(() => import('../pages/uikit/Cards'));
// const Carousels = React.lazy(() => import('../pages/uikit/Carousel'));
// const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
// const EmbedVideo = React.lazy(() => import('../pages/uikit/EmbedVideo'));
// const Grid = React.lazy(() => import('../pages/uikit/Grid'));
// const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
// const Modals = React.lazy(() => import('../pages/uikit/Modals'));
// const Notifications = React.lazy(() => import('../pages/uikit/Notifications'));
// const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvas'));
// const Paginations = React.lazy(() => import('../pages/uikit/Paginations'));
// const Popovers = React.lazy(() => import('../pages/uikit/Popovers'));
// const Progress = React.lazy(() => import('../pages/uikit/Progress'));
// const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
// const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
// const Tabs = React.lazy(() => import('../pages/uikit/Tabs'));
// const Tooltips = React.lazy(() => import('../pages/uikit/Tooltips'));
// const Typography = React.lazy(() => import('../pages/uikit/Typography'));
// const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
// const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
// const Ratings = React.lazy(() => import('../pages/uikit/Ratings'));

// icons
// const Dripicons = React.lazy(() => import('../pages/uikit/Dripicons'));
// const MDIIcons = React.lazy(() => import('../pages/uikit/MDIIcons'));
// const Unicons = React.lazy(() => import('../pages/uikit/Unicons'));
//
// forms
// const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
// const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
// const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
// const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
// const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
// const Editors = React.lazy(() => import('../pages/forms/Editors'));

// charts
// const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
// const BriteChart = React.lazy(() => import('../pages/charts/Brite'));
// const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));

// tables
// const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
// const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// widgets
// const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// maps
// const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));
// const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'uil-home-alt',
    header: 'Dashboard',
    children: [
        {
            path: '/dashboard',
            name: 'Analytics',
            component: AnalyticsDashboard,
            route: PrivateRoute,
        }
    ]
};

const otherPublicRoutes = [
    {
        path: '/error-404',
        name: 'Error - 404',
        component: ErrorPageNotFound,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: ServerError,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// auth
const authRoutes = [
    {
        path: '/account/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/account/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
];

const profileRoutes = [
    {
        path: '/account/profile',
        name: 'Login',
        component: Profile2,
        route: PrivateRoute,
    },
];

const monitoringRoutes = [

    {
        path: '/monitoring/devices/:deviceId/objects/:objectId',
        name: 'Monitoring Object',
        component: MonitoringObject,
        route: PrivateRoute,
    },
    {
        path: '/monitoring/devices/add',
        name: 'Add Monitoring Device',
        component: MonitoringDeviceAdd,
        route: PrivateRoute,
    },
    {
        path: '/monitoring/devices/:deviceId',
        name: 'Monitoring Device',
        component: MonitoringDevice,
        route: PrivateRoute,
    },
    {
        path: '/monitoring/devices',
        name: 'Monitoring',
        component: MonitoringDevicesList,
        route: PrivateRoute,
    },
];

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, ...profileRoutes, ...monitoringRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
